import DefaultNavbar from 'components/DefaultNavbar';
import DefaultFooter from 'components/DefaultFooter';
import Header from 'components/profile/Header';
import Content from 'components/profile/Content';
import {useEffect, useState} from "react";

export default function Profile(props) {
    const [id, setId] = useState(props.match.params.id)

    return (
        <>
            <div className="absolute w-full z-20">
                <DefaultNavbar />
            </div>
            <main>
                <Header />
                <Content id={id} />
            </main>
            <DefaultFooter />
        </>
    );
}
