import Card from '@material-tailwind/react/Card';
import CardImage from '@material-tailwind/react/CardImage';
import CardBody from '@material-tailwind/react/CardBody';
import Icon from '@material-tailwind/react/Icon';
import H4 from '@material-tailwind/react/Heading4';
import H6 from '@material-tailwind/react/Heading6';
import LeadText from '@material-tailwind/react/LeadText';
import Paragraph from '@material-tailwind/react/Paragraph';
import StatusCard from 'components/landing/StatusCard';
import Teamwork from 'assets/img/teamwork.jpeg';
import Legal from 'assets/img/legal.jpg'
import Enrpg from 'assets/img/enerygy_natural_resources.jpeg'
import Fiipg from 'assets/img/investment-infrastructure-group.jpeg'
import Ccapg from 'assets/img/ccapg.jpeg'
import Bdapg from 'assets/img/bdapg.jpeg'
import Drpg from 'assets/img/drpg.jpeg'

export default function WorkingSection() {
    return (
        <section className="pb-20 bg-gray-100 mt-10" id='practice-groups'>
            <div className="container max-w-7xl mx-auto px-4">
                <div className="flex flex-wrap relative z-50">
                    <StatusCard color="red" icon="stars" title="Energy and Natural Resources Practice Group (ENRPG)" bgImage={Enrpg}>
                        Electricity & Power<br/>
                        Oil & Gas<br/>
                        Maritime & Shipping<br/>
                        Mining
                    </StatusCard>
                    <StatusCard
                        color="lightBlue"
                        icon="autorenew"
                        title="Finance, Investments & Infrastructure Practice Group (FIIPG)"
                        bgImage={Fiipg}
                    >
                        Banking & Finance <br/>
                        Projects & Infrastructure <br/>
                        International Trade & Investments <br/>
                        Corporate Restructuring & Debt Recovery <br/>
                        Taxation
                    </StatusCard>
                    <StatusCard
                        color="teal"
                        icon="fingerprint"
                        title="Corporate and Commercial Advisory Practice Group (CCAPG)"
                        bgImage={Ccapg}
                    >
                        Corporate Governance & Company Secretarial <br/>
                        Government Liaison & Licensing <br/>
                        Regulatory & Legislative Compliance <br/>
                        Risk Mitigation & Due Diligence <br/>
                        Import Duty Exemption Certificate (IDEC)
                    </StatusCard>
                    <StatusCard
                        color="teal"
                        icon="fingerprint"
                        title="Business Development Advisory Practice Group (BDAPG)"
                        bgImage={Bdapg}
                    >
                        Privacy & Data Protection <br/>
                        Emerging Markets & Tech Disruptions <br/>
                        Intellectual Property & Branding <br/>
                        Business Intelligence <br/>
                        Energy Transition & Sustainability
                    </StatusCard>
                    <StatusCard
                        color="teal"
                        icon="fingerprint"
                        title="Dispute Resolution Practice Group (DRPG)"
                        bgImage={Drpg}
                    >
                        Litigation <br/>
                        Alternative Dispute Resolution <br/>
                        Administrative/Quasi-judicial Proceeding
                    </StatusCard>
                </div>

                <div className="flex flex-wrap items-center mt-32">
                    <div className="w-full md:w-5/12 px-4 mx-auto">
                        <div className="text-blue-gray-800 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                            <Icon name="people" size="3xl" />
                        </div>
                        <H4 color="gray">Our Ethics</H4>
                        <LeadText color="blueGray">
                            We are driven by a philosophy to render excellent
                            professional services within quick turn-around timelines.
                            Our services are tailor-made to meet our clients’ specific needs.
                            Our slogan hinges on Excellence, Precision and Speed.
                        </LeadText>
                    </div>

                    <div className="w-full md:w-4/12 px-4 mx-auto flex justify-center mt-24 lg:mt-0">
                        <Card>
                            <CardImage alt="Card Image" src={Legal} />
                            {/*<CardBody>*/}
                            {/*    <H6 color="gray">Top Notch Services</H6>*/}
                            {/*    <Paragraph color="blueGray">*/}
                            {/*        The Arctic Ocean freezes every winter and*/}
                            {/*        much of the sea-ice then thaws every summer,*/}
                            {/*        and that process will continue whatever*/}
                            {/*        happens.*/}
                            {/*    </Paragraph>*/}
                            {/*</CardBody>*/}
                        </Card>
                    </div>
                </div>
            </div>
        </section>
    );
}
