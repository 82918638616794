import H5 from '@material-tailwind/react/Heading5';
import LeadText from '@material-tailwind/react/LeadText';
import Icon from '@material-tailwind/react/Icon';

export default function DefaultFooter() {
    return (
        <>
            <footer className="relative bg-gray-100 pt-8 pb-6">
                <div className="container max-w-7xl mx-auto px-4">
                    <div className="flex flex-wrap text-center lg:text-left pt-6">
                        <div className="w-full lg:w-6/12 px-4">
                            <H5 color="gray">Bellwether Solicitors</H5>
                            <div className="-mt-4">
                                <LeadText color="blueGray">
                                    Flat 6, No. 1 Lokota Street, Opp.
                                    Febson Hotel, Wuse Zone 4, Abuja, FCT, Nigeria
                                </LeadText>
                            </div>
                        </div>
                        <div className="w-full lg:w-6/12 px-4">
                            <div className="flex flex-wrap items-top">
                                <div className="w-full lg:w-12/12 px-4 ml-auto md:mb-0 mb-8">
                                    <span className="block uppercase text-gray-900 text-sm font-serif font-medium mb-2">
                                        Useful Links
                                    </span>
                                    <div className="flex gap-2 mt-6 md:justify-start md:mb-0 mb-8 justify-center">
                                        <Icon name={'phone'} color={'black'} size={30}/><p>Tel: +234 803 407 9253</p>
                                    </div>
                                    <div className="flex gap-2 mt-6 md:justify-start md:mb-0 mb-8 justify-center">
                                        <Icon name={'email'} color={'black'} size={30}/><p>info@bellwethersolicitors.com.ng</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className="my-6 border-gray-300" />
                    <div className="flex flex-wrap items-center md:justify-between justify-center">
                        <div className="w-full md:w-4/12 px-4 mx-auto text-center">
                            <div className="text-sm text-gray-700 font-medium py-1">
                                Copyright © {new Date().getFullYear()} Bellwether Solicitors Nigeria.
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}
