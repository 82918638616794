import Chijioke from 'assets/img/Chijioke-Wonodi-pics-scaled-e1612781965785.jpeg';
import Gogo from 'assets/img/Gogo-Nicholas-pics-scaled-e1612783540346.jpeg';
import Atejimah from 'assets/img/atejimah.jpeg';

export const OUR_TEAM = [
    {
    key: 1,
    name: 'Chijioke Wonodi',
    email: 'chijiokewonodi@bellwethersolicitors.com.ng',
    image: Chijioke,
    details: 'Chijioke heads our Energy & Natural Resources and Business Development' +
        ' Advisory Practice Groups. Since his qualification as a legal practitioner, he' +
        ' has amassed enormous experience working in different entities within the energy' +
        ' industry including the Nigerian Electricity Regulatory Commission (NERC) where he' +
        ' started his career as a Legal Officer. At NERC, he gained deep insight and hands-on' +
        ' experience on the workings of the electricity industry and power sector. He later joined' +
        ' the legal department of Zuma Energy of Eta-Zuma Group, an energy-based firm in Abuja.' +
        ' Following a successful stint with Zuma Energy, he joined the legal department of ZKJ Energy' +
        ' Partners Limited (ZKJ) – an energy advisory and investment firm where he worked directly under' +
        ' the pioneer erstwhile MD/CEO of the Nigerian Bulk Electricity Trading PLC (NBET). At ZKJ, he' +
        ' worked on numerous high profile transactions and was part of the team that advised the Nigerian' +
        ' National Petroleum Corporation (NNPC) on the Abuja-Kaduna-Kano (AKK) Power Project. He has on' +
        ' numerous occasions undertaken (as external Solicitor), legal due diligence for, and advised the' +
        ' NERC on the issuance of power generation licenses to various applicant companies. He also' +
        ' interned with Rural Electrification Agency (REA) as an LLM candidate. Chijioke enjoys extensive' +
        ' goodwill and leverage within the energy industry and holds an LLM Degree in Energy Law from the' +
        ' prestigious Centre for Petroleum, Energy Economics and Law, University of Ibadan, Nigeria. He' +
        ' is a member of both the International Association for Energy Economics (IAEE) and the Nigerian' +
        ' Association for Energy Economics (NAEE).',
    linkedin: 'https://www.linkedin.com/in/chijioke-wonodi-6540b3103'
    },
    {
        key: 2,
        name: 'Gogo Nicholas',
        email: 'gogonicholas@bellwethersolicitors.com.ng',
        image: Gogo,
        details: 'Gogo comes with extensive corporate and commercial advisory experience and exposure. He' +
            ' heads our Corporate & Commercial Advisory as well as our Finance, Investments & Infrastructure' +
            ' Practice Groups. He interned with Faye Dikio, SAN & Associates, before joining the law firm of' +
            ' Fingerprint Solicitors, a corporate and commercial advisory firm where he acquired significant' +
            ' experience in real estate advisory and management, corporate restructuring and debt recovery,' +
            ' asset sale and acquisition while advising the Asset Management Corporation of Nigeria (AMCON).' +
            ' He has been involved in a variety of acquisition and disposition of properties of commercial' +
            ' nature worth billions of Naira including estates. He regularly advises on international project' +
            ' financing in the oil and gas industry and holds an LLM Degree in Oil and Gas Law from Robert' +
            ' Gordon University, Aberdeen, United Kingdom.',
    },
    {
        key: 3,
        name: 'Atejimah Nwokolu',
        email: 'atejimahnwokolu@bellwethersolicitors.com.ng',
        image: Atejimah,
        details: 'Atejimah is a highly skilled litigator and arbitrator and currently heads our Dispute ' +
            'Resolution Practice Group. He interned with the prestigious L.E. Nwosu SAN & Partners (a foremost' +
            ' environmental litigation firm) before he joined the law firm of Richard West & Co where he was' +
            ' involved in various high-profile litigation including a high-profile suit on negligence against' +
            ' an international oil company. He later joined Prime Law Partnership where he worked on various' +
            ' asset tracing and debt recovery instructions. He regularly advises clients seeking to do business' +
            ' in Nigeria through Foreign Direct and Foreign Portfolio Investment routes. He has previously' +
            ' served as the Legal Adviser to the Eleme Local Government Area of Rivers State. He has to his' +
            ' credit an LLM Degree in Business Law and is currently a PhD candidate at the Rivers State' +
            ' University, Nigeria.',
    },
]
