import Title from 'components/landing/Title';
import ContactCard from 'components/landing/ContactCard';
import Form from 'components/landing/Form';

export default function ContactSection() {
    return (
        <section className="pb-0 relative block bg-gray-100" id='contact-us'>
            <div className="container max-w-7xl mx-auto px-4 lg:pt-24">
                <Form />
            </div>
        </section>
    );
}
