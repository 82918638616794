import Title from 'components/landing/Title';
import TeamCard from 'components/landing/TeamCard';
import ContactCard from "./ContactCard";
import {OUR_TEAM} from "../../data/team";

export default function TeamSection() {
    return (
        <section className="pt-20 pb-48" id='about-us'>
            <Title heading="Who We Are" >
                We are a full-serviced law firm operating within the Nigerian legal space.
                Our Team of lawyers possess industry experience cut across diverse areas of
                legal specialisation straddling virtually every sector in Nigeria.
                With cutting-edge and bespoke advisory services to the range of our clientele,
                we are fast carving a niche for our practice within the Nigerian legal market.
            </Title>

            <div className="flex flex-wrap -mt-12 justify-center">
                <ContactCard icon="stars" title="Our Vision">
                    We aim to be the foremost corporate and commercial advisory law
                    firm in Nigeria within the next decade.
                </ContactCard>
                <ContactCard icon="insert_chart" title="Work Ethic and Philosophy">
                    We are driven by a philosophy to render excellent professional services
                    within quick turn-around timelines. Our services are tailor-made
                    to meet our client's specific needs. Our slogan hinges on Excellence,
                    Precision and Speed.
                </ContactCard>
            </div>
            <br/><br/><br/>
            <div className="container max-w-7xl mx-auto px-4" id='our-people'>
                <Title heading="Our People">
                    We are a team of Legal Experts
                </Title>
                <div className="flex flex-wrap">
                    {
                        OUR_TEAM.map((team_member, index) =>
                            <TeamCard
                                img={team_member.image}
                                name={team_member.name}
                                position={team_member.email}
                                keys={team_member.key}
                            />
                        )
                    }
                </div>
            </div>
        </section>
    );
}
