import { useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '@material-tailwind/react/Navbar';
import NavbarContainer from '@material-tailwind/react/NavbarContainer';
import NavbarWrapper from '@material-tailwind/react/NavbarWrapper';
import NavbarBrand from '@material-tailwind/react/NavbarBrand';
import NavbarToggler from '@material-tailwind/react/NavbarToggler';
import NavbarCollapse from '@material-tailwind/react/NavbarCollapse';
import Nav from '@material-tailwind/react/Nav';
import NavLink from '@material-tailwind/react/NavLink';
import Dropdown from '@material-tailwind/react/Dropdown';
import DropdownItem from '@material-tailwind/react/DropdownItem';
import Icon from '@material-tailwind/react/Icon';
import Button from '@material-tailwind/react/Button';;
import Logo from '../assets/img/logo.png'

export default function DefaultNavbar() {
    const [openNavbar, setOpenNavbar] = useState(false);

    return (
        <Navbar color="transparent" navbar>
            <NavbarContainer>
                <NavbarWrapper>
                    <a
                        href="/"
                        rel="noreferrer"
                    >
                        <NavbarBrand>
                            <img src={Logo} style={{width: 200, height: 100}} />
                        </NavbarBrand>
                    </a>
                    <NavbarToggler
                        onClick={() => setOpenNavbar(!openNavbar)}
                        color="white"
                    />
                </NavbarWrapper>

                <NavbarCollapse open={openNavbar}>
                    <Nav>
                        <div className="flex flex-col z-50 lg:flex-row lg:items-center">
                            <NavLink
                                href="/"
                                rel="noreferrer"
                                ripple="light"
                            >
                                {/*<Icon name="home" size="2xl" />*/}
                                &nbsp;Home
                            </NavLink>
                            <NavLink>
                                <h5
                                    style={{cursor: "pointer", color: 'white', fontFamily: 'Roboto', fontWeight: 500, fontSize: 12}}
                                    onClick={() => {
                                        window.location.href = "/#about-us"
                                        // document.getElementById("about-us").scrollIntoView()
                                    }}>
                                    &nbsp;ABOUT US
                                </h5>
                            </NavLink>

                            <NavLink>
                                <h5
                                    style={{cursor: "pointer", color: 'white', fontFamily: 'Roboto', fontWeight: 500, fontSize: 12}}
                                    onClick={() => {
                                        window.location.href = "/#practice-groups"
                                        // document.getElementById("practice-groups").scrollIntoView()
                                    }}>
                                    &nbsp;PRACTICE GROUPS
                                </h5>
                            </NavLink>

                            <NavLink>
                                <h5
                                    style={{cursor: "pointer", color: 'white', fontFamily: 'Roboto', fontWeight: 500, fontSize: 12}}
                                    onClick={() => {
                                        window.location.href = "/#our-people"
                                        // document.getElementById("our-people").scrollIntoView()
                                    }}>
                                    &nbsp;OUR PEOPLE
                                </h5>
                            </NavLink>

                            {/*<NavLink*/}
                            {/*    href="/"*/}
                            {/*    rel="noreferrer"*/}
                            {/*    ripple="light"*/}
                            {/*>*/}
                            {/*    Careers*/}
                            {/*</NavLink>*/}
                            {/*<a*/}
                            {/*    href="/"*/}
                            {/*    rel="noreferrer"*/}
                            {/*>*/}
                                <Button
                                    color="transparent"
                                    className="bg-white text-black ml-4"
                                    ripple="dark"
                                    onClick={() => {
                                        window.location.href = "/#contact-us"
                                        // document.getElementById("our-people").scrollIntoView()
                                    }}>
                                    Contact Us
                                </Button>
                            {/*</a>*/}
                        </div>
                    </Nav>
                </NavbarCollapse>
            </NavbarContainer>
        </Navbar>
    );
}
